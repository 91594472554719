import Amplify, { Auth } from 'aws-amplify'
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import appConfig from '../app-config'

const config = appConfig.regions[0]

// const config = {
//     region: process.env.REACT_APP_AMP_REGION,
//     userPoolId: process.env.REACT_APP_AMP_USER_POOL_ID,
//     userPoolWebClientId: process.env.REACT_APP_AMP_USER_POOL_WEB_CLIENT_ID,
//     oauth: {
//         domain: process.env.REACT_APP_OATH_DOMAIN,
//         scope: process.env.REACT_APP_OATH_SCOPE ? process.env.REACT_APP_OATH_SCOPE.split(' ') : [],
//         redirectSignIn: process.env.REACT_APP_OATH_REDIRECT_SIGN_ON,
//         redirectSignOut: process.env.REACT_APP_OATH_REDIRECT_SIGN_OUT,
//         responseType: process.env.REACT_APP_OATH_RESPONSE_TYPE,
//         options: {
//             AdvancedSecurityDataCollectionFlag: false
//         }
//     }
// }

Amplify.configure(config.amplify)

class AuthProvider {
  controller: any

  getToken = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((res) => {
          console.log('logged in', res)
          // resolve(res.getSignInUserSession().accessToken.jwtToken);
          // debugger;
          resolve({
            token: res.getSignInUserSession().accessToken.jwtToken,
            user: res.getUsername().split('Touchstone_')[1].split('@')[0]
          })
        })
        .catch((err) => {
          console.log('not logged in', err)
          Auth.federatedSignIn({ customProvider: config.signInProvider } as FederatedSignInOptionsCustom).catch((err1) => {
            console.log('login err', err1)
          })
        })
    })
  }

  logout = async (): Promise<any> => {
    console.debug('calling Auth.signOut()')
    await Auth.signOut()
  }
}

export default AuthProvider
