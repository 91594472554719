const appConfig = {
  "name": "elo-tracking",
  "version": "1.3.1",
  "stage": "develop",
  "regions": [
    {
      "name": "us-east-1",
      "isPrimaryRegion": true,
      "deployThisBuild": true,
      "regionDomain": "get.atlas-config-dev.mit.edu",
      "amplify": {
        "userPoolId": "us-east-1_14cFcpuS8",
        "userPoolWebClientId": "qp9l7e71tqri0q80f21krsl3o",
        "oauth": {
          "domain": "atlas-auth-dev.mit.edu",
          "scope": [
            "profile",
            "openid",
            "elo/user",
            "digital-id/search",
            "digital-id/user"
          ],
          "redirectSignIn": "https://elo-tracking-dev.mit.edu/",
          "redirectSignOut": "https://elo-tracking-dev.mit.edu/logout",
          "responseType": "token",
          "options": {
            "AdvancedSecurityDataCollectionFlag": false
          }
        }
      },
      "signInProvider": "Touchstone",
      "api": {
        "domain": "https://api-dev.mit.edu",
        "paths": {
          "digital-id": "digital-id-v1",
          "elo": "elo-tracking-v1"
        }
      }
    }
  ]
};
export default appConfig;